<template>
  <el-row type="flex">
    <el-tree class="tree" :data="data" node-key="id" :default-expanded-keys="[6,7]" :props="defaultProps"
             @node-click="handleNodeClick"/>
  </el-row>
</template>

<script>
export default {
  name: 'ArtNav',
  data() {
    return {
      data: [{
        id: 1,
        label: '1.  HTTP Basic',
        children: [],
      }, {
        id: 2,
        label: '2.  Digest Access',
        children: [],
      }, {
        id: 3,
        label: '3.  App Secret Key + HMAC',
      }, {
        id: 4,
        label: '4.  JWT – JSON Web Tokens',
      }, {
        id: 5,
        label: '5.  OAuth 1.0',
      }, {
        id: 6,
        label: '6.  OAuth 2.0',
        children: [{
          id: 6.1,
          label: '6.1 Authorization Code Flow 1111111111111111111111',
        }, {
          id: 6.2,
          label: '6.2  Client Credential Flow',
        }],
      }, {
        id: 7,
        label: '7.  小结',
        children: [{
          id: 7.1,
          label: '7.1 两个概念和三个术语',
        }, {
          id: 7.2,
          label: '7.2 明白一些初衷',
        }, {
          id: 7.3,
          label: '7.3 相关的注意事项',
        }],
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
    }
  }
}
</script>

<template>
  <el-row type="flex" justify="center" class="art-page">
    <el-col :span="17" class="left-side">
      <el-row class="content-box">
        <ArtContent/>
      </el-row>
    </el-col>
    <el-col :span="5" class="right-side">
      <el-row class="art-nav">
        <el-col class="nav">
          <ArtNav/>
        </el-col>
      </el-row>
      <el-row class="hot-box">
        <el-col class="tops">
          <TopList/>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<style lang="scss">
@import "../styles/article.scss";
</style>

<script>
import ArtContent from "@/components/ArtContent.vue";
import ArtNav from "@/components/ArtNav.vue";
import TopList from "@/components/TopList.vue";

export default {
  name: 'Article',
  components: {
    ArtContent, ArtNav, TopList
  }
}
</script>

<!--<script lang="ts">
import {Options,Vue} from "vue-class-component";
import ArtContent from "@/components/ArtContent.vue";
import ArtNav from "@/components/ArtNav.vue";
import TopList from "@/components/TopList.vue";

@Options({
  components:{ArtContent,ArtNav,TopList}
})
export default class Article extends  Vue{}
</script>-->


<template>
  <div>
  <el-row justify="start" class="dt">
    推荐阅读
  </el-row>
  <el-row justify="start" class="dd">
    <ul>
      <li>GO 编程模式：K8S VISITOR 模式</li>
      <li>GO 编程模式：K8S VISITOR 模式</li>
      <li>GO 编程模式：K8S VISITOR 模式</li>
      <li>GO 编程模式：K8S VISITOR 模式</li>
      <li>GO 编程模式：K8S VISITOR 模式</li>
      <li>GO 编程模式：K8S VISITOR 模式</li>
      <li>GO 编程模式：K8S VISITOR 模式</li>
    </ul>
  </el-row>
  </div>
</template>

<script>
export default {
  name: 'TopList'
}
</script>
